<template>
  <div>
    <div class="relative">
      <admin-navbar :onToggleSideBar="onToggleSideBar" />
      <sidebar
        :toggleSidebarProp="toggleSidebar"
        :sideBarForProp="sideBarFor"
        :savedList="savedData"
      />
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import api from "../api/services";
import { db } from "../db"
export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
  },
  data() {
    return {
      toggleSidebar: "-16",
      sideBarFor: "",
      savedData: {},
    };
  },
  async mounted() {
    this.$store.subscribe(async (mutation, state) => {
      let response;
      switch (mutation.type) {
        case "disp/CHANGE_SAVED_NAME":
          response = await api.DashBoardServices.getSavedContents();
          this.savedData = response;
          break;

        case "rect/CHANGE_SAVED_NAME":
          response = await api.DashBoardServices.getSavedContents();
          this.savedData = response;
          break;

        case "disp/UPDATE_CALL":
          response = await api.DashBoardServices.getSavedContents();
          this.savedData = response;
          break;
        default:
          break;
      }
    });
    // let customers = this.$serviceHelpers.getDetails("apollodart").customers;
    // this.$store.dispatch("data/setCustomer", {
    //   customer: customers[0],
    //   isInitial: true,
    // });
    this.$store.dispatch("data/getOptimizations");
    this.$store.dispatch("data/getAlertAnnotations");
    this.getUnitConversionDetails();
    // }
  },
  methods: {
    async getUnitConversionDetails() {
      try {
        const convesionFactDet =
          await api.WellServices.getUnitConversionFactorDetails();
        if(convesionFactDet.status==200){
          const { units_category,unit_conversion_factor } = convesionFactDet.data;
          if(units_category){
            try {
              await db.unitcategories.bulkPut(units_category);
            } catch (error) {
              
            }
          }
          if(unit_conversion_factor){
            try {
              await db.unitfactors.bulkPut(unit_conversion_factor);
            } catch (error) {
              
            }
          }
        }
      } catch (error) {}
    },
    async saveUnitConversion(data){

    },
    onToggleSideBar: async function (action) {
      let response;
      if (this.sideBarFor == action || this.sideBarFor == "") {
        this.sideBarFor = action;
        if (!this.$store.state.uis.leftSideBarOpen) {
          this.toggleSidebar = "0";
          if (action !== "Mnemonics") {
            if (this.$serviceHelpers.getDetails("670023242:az")) {
              response = await api.DashBoardServices.getSavedContents();

              if (response.status == 401) {
                this.savedData = response =
                  await api.DashBoardServices.getSavedContents();
              } else {
                this.savedData = response;
              }
            }
          }
        } else {
          this.sideBarFor = "";
          // this.toggleCollapseShow('bg-white m-2 py-3 px-6')
        }
        this.$store.dispatch("uis/toggleLeftSideBar");
        // this.$store.dispatch("disp/toggleLeftSideBar");
      } else {
        this.sideBarFor = action;
        response = await api.DashBoardServices.getSavedContents();
        this.savedData = response;
      }
    },

    darkMode: async function (action) {
      let response;
      if (!this.$store.state.rect.darkmode) {
      }
    },
    // this.$store.dispatch("rect/toggleLeftSideBar");
    // this.$store.dispatch("disp/toggleLeftSideBar");
  },
  // setup() {
  //   // const { addItem:addUnitCategory } = useIndexedDB("unitcategories", "units_category_id", [
  //   //   { name: "units_category_id", key: "units_category_id", unique: false },
  //   // ]);
  //   const { addItem:addCatogoryFactot } = useIndexedDB("unitfactors", "unit_conversion_factor_id", [
  //     { name: "unit_conversion_factor_id", key: "unit_conversion_factor_id", unique: false },
  //   ]);
  //   return{
  //     // addUnitCategory,
  //     addCatogoryFactot
  //   }
  // },
};
</script>
<style>
.bg_gray {
  background-color: #141414;
}
</style>
