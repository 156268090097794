<template>
  <div>
    <!-- Open Close icons to show hide admin nav bar -->
    <a
      href="#"
      @click="hide_n_show_header"
      class="nav_hide_icon"
      v-if="!this.$store.state.data.myBooleanValue"
    >
      <p v-if="!main_nav_show" class="menu_icons_bar">
        <span class="tooltip __close_box">open</span>
        <i class="fas fa-chevron-circle-down" aria-hidden="true"></i>
      </p>
      <p v-else class="menu_icons_bar">
        <span class="tooltip __close_box">close</span>
        <i class="fas fa-chevron-circle-up" aria-hidden="true"></i>
      </p>
    </a>
    <!-- End Open Close icons to show hide admin nav bar -->

    <nav
      id="header"
      class="top-0 left-0 w-full w-full z-10 shadow"
      v-if="main_nav_show"
    >
      <div class="navDiv flex items-center">
        <div class="headerdiv">
          <router-link to="/">
            <header id="parent">
              <!-- <h1 class="apollodart_heading">APOLLODART</h1> -->
              <!-- <div :style="{ background: 'var(--logoImage)' }"></div> -->
              <img
                :src="darkDark !== 'white' ? logoDark : logoLight"
                alt="apollodart"
                :width="60"
              />
            </header>
          </router-link>
        </div>
        <!-- Menus like specialty screens, custom screens -->
        <div class="navMiddleSection">
          <ul class="navMiddleList flex mb-0 list-none flex-wrap flex-row">
            <li
              v-if="is_super_admin || all_access.includes('SPECIALTY SCREEN')"
              class="-mb-px mr-2 last:mr-0 flex-auto cursor-pointer"
            >
              <a
                class="uppercase px-2 py-3 block"
                v-on:click="toggleTabs(1)"
                @click="sub_nav_show = true"
                v-bind:class="{
                  textColor: openTab !== 1,
                  activeTextColor: openTab === 1,
                }"
                >Specialty Screens</a
              >
            </li>
            <li
              v-if="is_super_admin || all_access.includes('WIDGETS')"
              class="-mb-px mr-2 last:mr-0 flex-auto cursor-pointer"
            >
              <a
                class="uppercase px-2 py-3 block"
                @click="sub_nav_show = true"
                v-on:click="toggleTabs(3)"
                v-bind:class="{
                  textColor: openTab !== 3,
                  activeTextColor: openTab === 3,
                }"
                >Widgets</a
              >
            </li>
            <li
              v-if="is_super_admin || all_access.includes('CUSTOM SCREENS')"
              class="-mb-px mr-2 last:mr-0 flex-auto cursor-pointer"
            >
              <a
                class="uppercase px-2 py-3 block"
                @click="sub_nav_show = true"
                v-on:click="toggleTabs(2)"
                v-bind:class="{
                  textColor: openTab !== 2,
                  activeTextColor: openTab === 2,
                }"
                >Custom Screens</a
              >
            </li>
            <li
              v-if="is_super_admin || all_access.includes('REPLAY')"
              class="-mb-px mr-2 last:mr-0 flex-auto cursor-pointer"
            >
              <a
                class="uppercase px-2 py-3 block"
                @click="sub_nav_show = true"
                v-on:click="toggleTabs(4)"
                v-bind:class="{
                  textColor: openTab !== 4,
                  activeTextColor: openTab === 4,
                }"
                >Replay</a
              >
            </li>
          </ul>
        </div>
        <!-- End Menus like specialty screens, custom screens -->

        <!-- Navigation buttons/icons of left corners -->
        <div class="navRightSection ml-auto pr-3">
          <div
            class="navRightSubDiv flex relative inline-block float-right items-center"
          >
            <!-- notification icon -->
            <button
              v-if="
                (is_super_admin ||
                  all_access.includes('ALERTS AND NOTIFICATIONS')) &&
                darkDark !== 'white'
              "
              ref="settingsBtnPoper"
              @click="OnNotificationIconClicked($event, 'alert')"
            >
              <svg
                class="bellIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 16.5C9.17 16.5 8.5 15.83 8.5 15H11.5C11.5 15.83 10.83 16.5 10 16.5ZM15 14H5V13L6 12V9.39C6 7.27 7.03 5.47 9 5V4.5C9 3.93 9.43 3.5 10 3.5C10.57 3.5 11 3.93 11 4.5V5C12.97 5.47 14 7.28 14 9.39V12L15 13V14Z"
                  fill="#fff"
                />
              </svg>
            </button>
            <button
              v-if="
                (is_super_admin ||
                  all_access.includes('ALERTS AND NOTIFICATIONS')) &&
                darkDark === 'white'
              "
              ref="settingsBtnPoper"
              @click="OnNotificationIconClicked($event, 'alert')"
            >
              <svg
                class="bellLight"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 16.5C9.17 16.5 8.5 15.83 8.5 15H11.5C11.5 15.83 10.83 16.5 10 16.5ZM15 14H5V13L6 12V9.39C6 7.27 7.03 5.47 9 5V4.5C9 3.93 9.43 3.5 10 3.5C10.57 3.5 11 3.93 11 4.5V5C12.97 5.47 14 7.28 14 9.39V12L15 13V14Z"
                  fill="#000"
                />
              </svg>
            </button>
            <!-- capture screen and send mail to support -->
            <button
              v-if="is_super_admin || all_access.includes('CUSTOMER SUPPORT')"
              class="captr_N_SendMail"
              @click="captureScreen_SendMail"
            >
              <!-- <i class="fas fa-share-square" style="font-size: 18px;"></i> -->
              <i class="fas fa-envelope mt-1" style="font-size: 16px"></i>
            </button>
            <!-- profile icon -->
            <a
              class="flex items-center text-xs uppercase font-thin"
              href="#pablo"
              ref="btnDropdownRef"
              v-on:click="toggleDropdown($event)"
            >
              <svg
                v-if="darkDark !== 'white'"
                xmlns="http://www.w3.org/2000/svg"
                class="nav_1_icon settingsIcon"
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.1401 10.94C17.1801 10.64 17.2001 10.33 17.2001 10C17.2001 9.68003 17.1801 9.36002 17.1301 9.06002L19.1601 7.48002C19.3401 7.34002 19.3901 7.07002 19.2801 6.87002L17.3601 3.55002C17.2401 3.33002 16.9901 3.26002 16.7701 3.33002L14.3801 4.29002C13.8801 3.91002 13.3501 3.59002 12.7601 3.35002L12.4001 0.810024C12.3601 0.570024 12.1601 0.400024 11.9201 0.400024H8.08011C7.84011 0.400024 7.65011 0.570024 7.61011 0.810024L7.25011 3.35002C6.66011 3.59002 6.12011 3.92002 5.63011 4.29002L3.24011 3.33002C3.02011 3.25002 2.77011 3.33002 2.65011 3.55002L0.74011 6.87002C0.62011 7.08002 0.66011 7.34002 0.86011 7.48002L2.89011 9.06002C2.84011 9.36002 2.80011 9.69002 2.80011 10C2.80011 10.31 2.82011 10.64 2.87011 10.94L0.84011 12.52C0.66011 12.66 0.61011 12.93 0.72011 13.13L2.64011 16.45C2.76011 16.67 3.01011 16.74 3.23011 16.67L5.62011 15.71C6.12011 16.09 6.65011 16.41 7.24011 16.65L7.60011 19.19C7.65011 19.43 7.84011 19.6 8.08011 19.6H11.9201C12.1601 19.6 12.3601 19.43 12.3901 19.19L12.7501 16.65C13.3401 16.41 13.8801 16.09 14.3701 15.71L16.7601 16.67C16.9801 16.75 17.2301 16.67 17.3501 16.45L19.2701 13.13C19.3901 12.91 19.3401 12.66 19.1501 12.52L17.1401 10.94ZM10.0001 13.6C8.02011 13.6 6.40011 11.98 6.40011 10C6.40011 8.02002 8.02011 6.40002 10.0001 6.40002C11.9801 6.40002 13.6001 8.02002 13.6001 10C13.6001 11.98 11.9801 13.6 10.0001 13.6Z"
                  fill="#fff"
                ></path>
              </svg>
              <svg
                v-if="darkDark === 'white'"
                class="settingsLight"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.1401 10.94C17.1801 10.64 17.2001 10.33 17.2001 10C17.2001 9.68 17.1801 9.36 17.1301 9.06L19.1601 7.48C19.3401 7.34 19.3901 7.07 19.2801 6.87L17.3601 3.55C17.2401 3.33 16.9901 3.26 16.7701 3.33L14.3801 4.29C13.8801 3.91 13.3501 3.59 12.7601 3.35L12.4001 0.810001C12.3601 0.570001 12.1601 0.400002 11.9201 0.400002H8.08011C7.84011 0.400002 7.65011 0.570001 7.61011 0.810001L7.25011 3.35C6.66011 3.59 6.12011 3.92 5.63011 4.29L3.24011 3.33C3.02011 3.25 2.77011 3.33 2.65011 3.55L0.74011 6.87C0.62011 7.08 0.66011 7.34 0.86011 7.48L2.89011 9.06C2.84011 9.36 2.80011 9.69 2.80011 10C2.80011 10.31 2.82011 10.64 2.87011 10.94L0.84011 12.52C0.66011 12.66 0.61011 12.93 0.72011 13.13L2.64011 16.45C2.76011 16.67 3.01011 16.74 3.23011 16.67L5.62011 15.71C6.12011 16.09 6.65011 16.41 7.24011 16.65L7.60011 19.19C7.65011 19.43 7.84011 19.6 8.08011 19.6H11.9201C12.1601 19.6 12.3601 19.43 12.3901 19.19L12.7501 16.65C13.3401 16.41 13.8801 16.09 14.3701 15.71L16.7601 16.67C16.9801 16.75 17.2301 16.67 17.3501 16.45L19.2701 13.13C19.3901 12.91 19.3401 12.66 19.1501 12.52L17.1401 10.94ZM10.0001 13.6C8.02011 13.6 6.40011 11.98 6.40011 10C6.40011 8.02 8.02011 6.4 10.0001 6.4C11.9801 6.4 13.6001 8.02 13.6001 10C13.6001 11.98 11.9801 13.6 10.0001 13.6Z"
                  fill="#000"
                />
              </svg>
            </a>
            <!-- toggle btn and img to show popup  -->
            <div class="relative">
              <!-- <input
                type="checkbox"
                class="checkbox hidden"
                id="checkbox"
                @change="modeToggle()"
                :class="darkDark"
                value=""
              />
              <label for="checkbox" class="checkbox-label">
                <i class="fas fa-moon theme_icon"></i>
                <i class="fas fa-sun theme_icon"></i>
                <span class="ball"></span>
              </label> -->
              <img
                class="cursor-pointer"
                @click="OnNotificationIconClicked($event, 'theme')"
                width="17"
                src="../../assets/img/color-palette.png"
                alt="theme image"
              />
              <div
                :style="{ visibility: 'hidden' }"
                id="themes_container_popup"
                class="absolute shadow-lg themModalContainer"
              >
                <div class="">
                  <!-- <div class="shadow px-3 py-1"><p class="themeHeading">Choose Theme</p></div> -->
                  <div class="flex flex-col justify-around py-3">
                    <div class="px-2 shadow mb-1">
                      <div
                        for="greyColor"
                        @click="
                          OnNotificationIconClicked($event, 'theme'),
                            modeToggle('grey')
                        "
                        class="w-full flex justify-between items-center mb-1 circles cursor-pointer"
                      >
                        <div
                          :class="{ themeActive: darkDark == 'grey' }"
                          class="circle1"
                        ></div>
                      </div>
                    </div>
                    <div class="px-2 shadow mb-1">
                      <div
                        for="whiteColor"
                        @click="
                          OnNotificationIconClicked(
                            $event,
                            'theme',
                            modeToggle('white')
                          )
                        "
                        class="w-full flex justify-between items-center mb-1 circles cursor-pointer"
                      >
                        <div
                          :class="{ themeActive: darkDark == 'white' }"
                          class="circle2"
                        ></div>
                      </div>
                    </div>
                    <div class="px-2 mb-1">
                      <div
                        for="blueColor"
                        @click="
                          OnNotificationIconClicked($event, 'theme'),
                            modeToggle('blue')
                        "
                        class="w-full flex justify-between items-center circles cursor-pointer"
                      >
                        <div
                          :class="{ themeActive: darkDark == 'blue' }"
                          class="circle3"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <!-- Drop down for setting icon, having customer list, redirection, user profile -->
            <SettingDropdowns
              :toggleDropdown="toggleDropdown"
              :toggleUserProfile="toggleUserProfile"
            />
            <div
              v-if="
                is_super_admin ||
                all_access.includes('ALERTS AND NOTIFICATIONS')
              "
              ref="settingsPopUpPoper"
              id="menu_top"
              :style="{ visibility: 'hidden' }"
              class="notificationPopUp"
            >
              <Popper class="popperOfAlert" arrow content="Please Hold & Drag">
                <p
                  :sname="gettingDisplayId('AlertS')"
                  :draggable="true"
                  @dragstart="
                    startDragSaved($event, {
                      isAlertScreen: true,
                      name: 'display',
                      savedId: 'idalerts',
                      savedName: 'Alerts',
                    })
                  "
                  class="appS_ label_alerts cursor-pointer"
                >
                  Alerts & Notifications
                </p>
              </Popper>
            </div>
            <!-- alert Screen and right sidebar -->
          </div>
        </div>

        <!-- modal for confirm dialogue box to capture screen starts -->
        <div class="confirm_box" v-if="capture_screen_confirm_box">
          <div class="relative my-6 mx-auto width-auto">
            <div
              class="confirm_Dia_Box border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none p-4"
            >
              <!-- icon -->
              <div class="text-center mb-4 mt-2">
                <i
                  class="far fa-question-circle confirm_Box_Icon"
                  style="font-size: 2rem"
                ></i>
              </div>
              <!-- content -->
              <div class="py-3 pt-0">
                <p class="text-xs text-center uppercase">
                  Do you want to capture current screen ?
                </p>
              </div>
              <div class="pt-1 flex justify-around text-sm">
                <button
                  class="Confirm_Dialogue_yes text-xs uppercase text-white font-semibold bgClr focus:outline-none py-1 my-2 flex px-4 rounded-full mx-auto"
                  @click="captureYesHandler"
                >
                  Yes
                </button>
                <button
                  class="Confirm_Dialogue_no text-xs uppercase border focus:outline-none font-semibold py-1 my-2 flex px-4 rounded-full mx-auto"
                  @click="dont_capture_screen"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal for confirm dialogue box to capture screen ends -->
        <!-- send mail modal code starts -->
        <SendMailPopUp
          v-show="show_sendMail_modal"
          ref="sendMainComp"
          :captchureClickhandler="yes_capture_screen"
          :closeModelhandler="close_sendMail_modal"
        />
        <div v-if="showMask" class="mask fixed h-full w-full" style="top:0;left:0; z-index: 9999;"></div>
        <!-- send mail modal code end -->
      </div>
      <a
        href="#"
        @click="sub_nav_show = !sub_nav_show"
        class="nav_hide_icon"
        v-if="!this.$store.state.data.myBooleanValue"
      >
        <p v-if="!sub_nav_show" class="menu_icons_bar">
          <span class="tooltip __close_box">open</span>
          <i class="fas fa-chevron-circle-down" aria-hidden="true"></i>
        </p>
        <p v-else class="menu_icons_bar">
          <span class="tooltip __close_box">close</span>
          <i class="fas fa-chevron-circle-up" aria-hidden="true"></i>
        </p>
      </a>
      <div class="navBar2" v-if="sub_nav_show">
        <div class="flex-auto">
          <div class="flex items-center w-full" name="expand">
            <div
              v-if="is_super_admin || all_access.includes('MNEMONICS')"
              class="flex items-center justify-center sideBarBackground"
            >
              <a
                href="#"
                class="flex items-center justify-center"
                @click="toggleLeftSideDbBar('Mnemonics')"
                :class="$store.state.uis.leftSideBarOpen ? 'activesvg' : ''"
              >
                <svg
                  v-if="darkDark !== 'white'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="15"
                  viewBox="0 0 20 15"
                  fill="none"
                  data-v-1a2380ac=""
                >
                  <path
                    d="M0 6.25H15V8.75H0V6.25ZM0 0H20V2.5H0V0ZM0 15H9.04375V12.5H0V15Z"
                    fill="#fff"
                    data-v-1a2380ac=""
                  ></path>
                </svg>
                <svg
                  v-if="darkDark === 'white'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="15"
                  viewBox="0 0 20 15"
                  fill="none"
                  :class="$store.state.uis.leftSideBarOpen ? 'white' : ''"
                >
                  <path
                    d="M0 6.25H15V8.75H0V6.25ZM0 0H20V2.5H0V0ZM0 15H9.04375V12.5H0V15Z"
                    fill="#000"
                  />
                </svg>
              </a>
            </div>
            <!-- Specialty screens -->
            <transition
              v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }"
            >
              <nav id="header" class="w-full">
                <div
                  class="w-full mx-auto flex flex-wrap items-center mt-0 py-0 md:pb-0"
                >
                  <div class="w-2/4 pl-2 md:pl-0 m-0 h-full flex">
                    <ul class="flex flex-col lg:flex-row list-none sub_">
                      <template v-for="screen in specialty">
                        <li
                          :key="screen.name"
                          v-if="
                            is_super_admin ||
                            (all_access.includes('SPECIALTY SCREEN') &&
                              all_access.includes(screen.name))
                          "
                          class="flex items-center text-white text-xs font-thin leading-relaxed inline-block whitespace-nowrap uppercase mx-auto"
                        >
                          <button
                            :sname="gettingDisplayId(screen.name)"
                            :class="{
                              activenav: displayedRects.includes(
                                gettingDisplayId(screen.name)
                              ),
                            }"
                            class="menuItem h-full"
                            :draggable="true"
                            @dragstart="
                              startDragSaved($event, {
                                ...screen.screen,
                                name: 'display',
                                savedId: screen._id,
                                savedName: screen.name,
                              })
                            "
                          >
                            <Popper
                              :class="darkDark"
                              arrow
                              content="Please Hold & Drag"
                            >
                              {{ screen.name }}
                            </Popper>
                          </button>
                        </li>
                      </template>
                    </ul>
                  </div>
                  <div class="w-1/5 pr-0">
                    <div class="flex relative inline-block"></div>
                  </div>
                </div>
              </nav>
            </transition>
            <!-- Dashboard -->
            <transition
              v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }"
            >
              <nav id="header" class="w-11/12 z-10">
                <div
                  class="w-full ml-auto flex flex-wrap items-center mt-0 md:pb-0 menuItem"
                >
                  <div class="w-2/6 pl-2 md:pl-0 h-full flex items-center">
                    <ul class="flex flex-col lg:flex-row list-none pr-5 h-full">
                      <router-link
                        v-if="
                          is_super_admin ||
                          (all_access.includes('CUSTOM SCREENS') &&
                            all_access.includes('DASHBOARD'))
                        "
                        to="#"
                        @click="toggleLeftSideDbBar('DashBoard')"
                        class="tgglAsideBar"
                        :class="[
                          clickedLink === 'DashBoard' ? 'activeClass' : '',
                        ]"
                      >
                        <li
                          class="flex items-center menuItem h-full"
                          style="margin-left: 0px"
                        >
                          DB
                        </li>
                      </router-link>
                      <router-link
                        v-if="
                          is_super_admin ||
                          (all_access.includes('CUSTOM SCREENS') &&
                            all_access.includes('SCREENS'))
                        "
                        to="#"
                        @click="toggleLeftSideDbBar('Screens')"
                        class="tgglAsideBar"
                        :class="[
                          clickedLink === 'Screens' ? 'activeClass' : '',
                        ]"
                      >
                        <li
                          class="flex items-center menuItem h-full"
                          style="margin-left: 0px"
                        >
                          SRN
                        </li>
                      </router-link>
                      <router-link
                        v-if="
                          is_super_admin ||
                          (all_access.includes('CUSTOM SCREENS') &&
                            all_access.includes('BACKGROUNDS'))
                        "
                        to="#"
                        @click="toggleLeftSideDbBar('Background')"
                        class="tgglAsideBar"
                        :class="[
                          clickedLink === 'Background' ? 'activeClass' : '',
                        ]"
                      >
                        <li
                          class="flex items-center menuItem h-full"
                          style="margin-left: 0px"
                        >
                          BG
                        </li>
                      </router-link>
                      <router-link
                        v-if="
                          is_super_admin ||
                          (all_access.includes('CUSTOM SCREENS') &&
                            all_access.includes('MNEMONICS'))
                        "
                        to="#"
                        @click="toggleLeftSideDbBar('Mnemonics')"
                        class="tgglAsideBar"
                        :class="[
                          clickedLink === 'Mnemonics' ? 'activeClass' : '',
                        ]"
                      >
                        <li
                          class="flex items-center menuItem h-full"
                          style="margin-left: 0px"
                        >
                          MN
                        </li>
                      </router-link>
                    </ul>
                  </div>
                  <div class="pl-2 md:pl-10 ml-auto">
                    <!-- 6/12 -->
                    <ul class="list-none w-full pl-6">
                      <li
                        v-if="
                          is_super_admin ||
                          (all_access.includes('CUSTOM SCREENS') &&
                            all_access.includes('SCREEN'))
                        "
                        class="flex items-center text-white text-xs font-thin leading-relaxed inline-block mr-4 py-1 whitespace-nowrap uppercase"
                      >
                        <div class="border-r" style="height: 70%"></div>
                        <div class="w-1/5 pl-2 md:pl-0">
                          <Popper
                            :class="darkDark"
                            arrow
                            content="Please Hold & Drag"
                          >
                            <button
                              draggable="true"
                              @dragstart="
                                startDrag($event, {
                                  name: 'display',
                                  width: 600,
                                  height: 450,
                                  color: 'green',
                                })
                              "
                              class="bgClr text-white text-xs font-semibold uppercase px-4 py-0.5 rounded shadow"
                              type="button"
                            >
                              screen
                            </button>
                          </Popper>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    v-if="
                      is_super_admin ||
                      (all_access.includes('CUSTOM SCREENS') &&
                        all_access.includes('SAVE'))
                    "
                    class="pr-0 flex justify-end"
                  >
                    <div class="flex relative inline-block">
                      <li
                        class="flex items-center text-white text-xs font-thin leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
                      ></li>
                      <router-link to="#">
                        <button
                          class="navBar2saveBtn bgClr text-white text-xs font-semibold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-3 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                          type="button"
                          @click="toggleModal()"
                        >
                          Save
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </nav>
            </transition>
            <!-- Widgets -->
            <transition
              v-bind:class="{ hidden: openTab !== 3, block: openTab === 3 }"
            >
              <nav
                id="header"
                class="w-full z-10 widget__overflow"
                @wheel="mouseWheelForWidgetScroll"
              >
                <div
                  class="w-full mx-auto flex flex-wrap items-center mt-0 py-0 md:pb-0"
                >
                  <div class="sec_nav_2 w-3/5 pl-2 md:pl-0">
                    <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
                      <template v-for="(widget, index) of defaultWidgets">
                        <li
                          :key="index"
                          v-if="
                            is_super_admin ||
                            (all_access.includes('WIDGETS') &&
                              all_access.includes(widget.permissionName))
                          "
                          class="flex menuItem items-center text-white text-xs font-thin leading-relaxed inline-block text_xs_ my-0.5 mr-1 py-0.5 whitespace-nowrap uppercase"
                          :class="{
                            activenav: dragedWidgetNames.includes(widget.name),
                          }"
                        >
                          <div
                            draggable="true"
                            @dragstart="startDrag($event, widget)"
                          >
                            <Popper
                              :class="darkDark"
                              arrow
                              content="Please Hold & Drag on to the screen"
                            >
                              {{ widget.displayName }}
                            </Popper>
                          </div>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>

                <div
                  class="right_arrow_div"
                  :style="getTopPostionsForArrows()"
                  @click="scrollArrowClicked('right')"
                >
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/>
                </svg>
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                    />
                  </svg> -->
                </div>
                <div
                  class="left_arrow_div"
                  :style="getTopPostionsForArrows()"
                  @click="scrollArrowClicked('left')"
                >
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/>
                </svg>
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                    />
                  </svg> -->
                </div>
              </nav>
            </transition>

            <!-- Replay screens -->
            <transition
              v-bind:class="{ hidden: openTab !== 4, block: openTab === 4 }"
            >
              <nav id="header" class="w-full">
                <div
                  class="w-full mx-auto flex flex-wrap items-center mt-0 py-0 md:pb-0"
                >
                  <div class="w-2/4 pl-2 md:pl-0 m-0 h-full flex">
                    <ul class="flex flex-col lg:flex-row list-none sub_">
                      <template v-for="screen in specialty">
                        <li
                          :key="screen.name"
                          v-if="
                            (is_super_admin ||
                              (all_access.includes('SPECIALTY SCREEN') &&
                                all_access.includes(screen.name))) &&
                            screen.isReplay == isReplay
                          "
                          class="flex items-center text-white text-xs font-thin leading-relaxed inline-block whitespace-nowrap uppercase mx-auto"
                        >
                          <button
                            :sname="gettingDisplayId(screen.name)"
                            :class="{
                              activenav: displayedRects.includes(
                                gettingDisplayId(screen.name)
                              ),
                            }"
                            class="menuItem h-full"
                            :draggable="true"
                            @dragstart="
                              startDragSaved($event, {
                                ...screen.screen,
                                name: 'display',
                                savedId: screen._id,
                                savedName: screen.name,
                                isReplay: screen.isReplay,
                                isspecialityscreen: screen.isReplay
                                  ? false
                                  : screen?.isspecialityscreen,
                              })
                            "
                          >
                            <Popper
                              :class="darkDark"
                              arrow
                              content="Please Hold & Drag"
                            >
                              {{ screen.name }}
                            </Popper>
                          </button>
                        </li>
                      </template>
                    </ul>
                  </div>
                  <div class="w-1/5 pr-0">
                    <div class="flex relative inline-block"></div>
                  </div>
                </div>
              </nav>
            </transition>
          </div>
        </div>
      </div>
    </nav>
    <!-- Dashboard Model -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden model_height model_bg"
      id="dashboard-modal"
    >
      <div
        class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 model_height"
      >
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div class="container mx-auto px-4 h-full">
          <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 modal_width px-4">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg_model border-0"
              >
                <div class="rounded-t mb-0 px-6 py-6">
                  <div class="text-center mb-3">
                    <h6 class="text-white text-md font-thin">Save Dashboard</h6>
                  </div>
                </div>
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0 modal_body">
                  <section>
                    <input
                      id="one"
                      type="radio"
                      v-model="radioVal"
                      @click="this.errcode = ''"
                      value="0"
                    />
                    <label for="one" class="text-white mx-2">New </label>
                    <input
                      id="two"
                      type="radio"
                      v-model="radioVal"
                      @click="this.errcode = ''"
                      value="1"
                    />
                    <label for="two" class="text-white mx-2">Existing</label>
                    <br />
                  </section>
                  <form>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-white text-xs font-thin my-4 text-left label_"
                        htmlFor="grid-password"
                        v-if="radioVal == '0'"
                      >
                        Save As
                        <input
                          type="text"
                          class="border-0 px-3 py-3 placeholder-blueGray-50 text-white bg_input rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 mt-2"
                          v-model="dashboard_name"
                        />
                      </label>
                      <label
                        class="block uppercase text-white text-xs font-thin text-center my-4 label_"
                        htmlFor="grid-password"
                        v-if="radioVal == '1'"
                      >
                        <div class="text-left">
                          Save
                        </div>
                        <input
                          @focus="this.isSearching = true"
                          @blur="onBlurSelect()"
                          type="text"
                          class="border-0 px-3 py-3 placeholder-blueGray-50 text-white bg_input rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 mt-2"
                          placeholder="Search.."
                          @input="filterFunction($event)"
                          v-model="search_input"
                        />
                        <div
                          class="dropdown-list_select"
                          v-if="this.isSearching"
                        >
                          <a
                            class="dropdown-content_select_a"
                            @click="onSelectDb(db)"
                            :key="db._id"
                            v-for="db in searchList"
                            >{{ db.name }}</a
                          >
                        </div>
                        <!-- </div> -->
                      </label>
                    </div>
                    <div class="text-center mt-3 flex">
                      <button
                        type="button"
                        class="px-4 bg-gray-500 text-white text-sm bg_cancel bg_modal_btns rounded hover:bg-gray-700 text-sm font-thin button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                        @click="toggleModal()"
                      >
                        <i class="fas fa-times"></i> Cancel
                      </button>
                      <button
                        class="bg-green-600 bg_create bg_modal_btns text-white active:bg-green-800 text-sm font-thin button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                        type="button"
                        @click="onSaveDashboard()"
                      >
                        <i class="fas fa-plus"></i> Save
                      </button>
                    </div>
                  </form>
                  <div class="text-red-500">
                    {{ errcode }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Profile Model -->
    <UserProfile v-if="showProfile" :toggleProfile="toggleUserProfile" />
  </div>
</template>
<script>
import UserProfile from "@/components/Navbars/ProfileModel.vue";
import { ref } from "vue";
import URL from "../../config";
import api from "../../api/services";
import Popper from "vue3-popper";
import SettingDropdowns from "./SettingDropdowns.vue";
import SendMailPopUp from "./SendMail.vue";
import darklogoImage from "../../assets/img/apollo_logo_light.png";
import lightlogoImage from "../../assets/img/apollo_logo.png";
export default {
  components: {
    Popper,
    UserProfile,
    SettingDropdowns,
    SendMailPopUp,
  },
  props: {
    onToggleSideBar: { type: Function },
    lwdPropsOpened: Boolean,
  },
  data() {
    return {
      isGreyChecked: false,
      isBlueChecked: false,
      isWhiteChecked: false,
      isThemeIconClicked: false,
      customerNames: [],
      URL: null,
      showProfile: false,
      usersData: null,

      // displayedRects:[],
      isAlertClicked: false,
      widgets: [],
      logoDark: darklogoImage,
      logoLight: lightlogoImage,
      defaultWidgets: [
        {
          name: "display",
          width: 600,
          height: 450,
          color: "green",
          displayName: "Screen",
          permissionName: "SCREEN",
        },
        {
          name: "label",
          width: 180,
          height: 44,
          borderDisplay: false,
          displayName: "LABEL",
          permissionName: "LABEL",
        },
        {
          name: "numeric",
          displayName: "NUMERIC",
          width: 180,
          height: 44,
          title: "title",
          borderDisplay: false,
          unitDisplay: true,
          permissionName: "NUMERIC",
        },
        {
          name: "vertical-bar",
          width: 70,
          height: 240,
          borderDisplay: false,
          displayName: "BAR GAUGE",
          unitDisplay: true,
          permissionName: "BAR GAUGE",
        },
        {
          name: "gauge",
          width: 140,
          height: 140,
          aspectRatio: true,
          borderDisplay: true,
          displayName: "RADIAL GAUGE",
          unitDisplay: true,
          permissionName: "RADIAL GAUGE",
        },
        // {
        //   name: "killsheet",
        //   displayName: "KILL SHEET",
        //   width: 1200,
        //   height: window.innerHeight,
        //   title: "title",
        //   borderDisplay: false,
        //   permissionName: "KILL SHEET",
        // },
        // {
        //   name: "swaborsurge",
        //   displayName: "SWAB AND SURGE",
        //   width: 1200,
        //   height: 515,
        //   title: "title",
        //   borderDisplay: false,
        //   permissionName: "SWAB AND SURGE",
        // },
        {
          name: "DirectionalDifficulty",
          displayName: "DIRECTIONAL DIFFICULTY",
          width: 750,
          height: 450,
          borderDisplay: false,
          permissionName: "DIRECTIONAL DIFFICULTY",
        },
        {
          name: "XYAxis",
          width: 750,
          height: 450,
          borderDisplay: false,
          displayName: "XY AXIS",
          permissionName: "XY AXIS",
        },
        {
          name: "Reports",
          width: 750,
          height: 450,
          borderDisplay: false,
          displayName: "Reports",
          permissionName: "Reports",
        },
        {
          name: "trend",
          width: 130,
          height: 50,
          displayName: "TREND",
          permissionName: "TREND",
          isReplay: false,
        },
        {
          name: "plotly-graph",
          width: 400,
          height: 400,
          displayName: "4D",
          permissionName: "4D",
          draggable: false,
        },
        {
          name: "Background",
          width: 250,
          height: 250,
          color: "SlateBlue",
          displayName: "BACKGROUND",
          permissionName: "BACKGROUND",
        },
        {
          name: "data-table",
          width: 725,
          height: 250,
          minh: 100,
          minw: 320,
          displayName: "SURVEY TABLE",
          permissionName: "SURVEY TABLE",
        },
        {
          name: "well-detail",
          width: 580,
          height: 250,
          minh: 100,
          minw: 320,
          displayName: "WELL INFO",
          permissionName: "WELL INFO",
        },
        {
          name: "directional",
          // aspectRatio: true,
          width: 270,
          height: 270,
          minh: 225,
          minw: 225,
          displayName: "ROSEBUD",
          permissionName: "ROSEBUD",
        },
        {
          name: "line-graph",
          width: 600,
          height: 200,
          xAutoScale: true,
          xRangeStart: -10000,
          xRangeEnd: 10000,
          yRangeStart: -10000,
          yRangeEnd: 10000,
          yAutoScale: true,
          displayName: "TVD SECTION",
          permissionName: "TVD SECTION",
        },
        {
          name: "line-graph-tvd",
          width: 600,
          height: 200,
          displayName: "VERTICAL SECTION",
          permissionName: "VERTICAL SECTION",
        },
        {
          name: "plan-vs-actual",
          width: 400,
          height: 400,
          xAutoScale: false,
          xRangeStart: 1000,
          xRangeEnd: -1000,
          zAutoScale: false,
          zRangeStart: -7000,
          zRangeEnd: 7000,
          displayName: "TRAJECTORY 3D",
          permissionName: "TRAJECTORY 3D",
          draggable: false,
        },
      ],
      widgetNames: [
        "label",
        "numeric",
        "killsheet",
        "trend",
        "vertical-bar",
        "gauge",
        "plotly-graph",
        "Background",
        "data-table",
        "well-detail",
        "directional",
        "line-graph",
        "line-graph-tvd",
        "plan-vs-actual",
      ],
      // darkDark: false,
      dropdownPopoverShow: false,
      searchList: [],
      isDrillmetrics: false,
      tabShowOne: true,
      show: true,
      sub_nav_show: true,
      // main_nav_show: true,
      dropdownPopoverShow: false,
      collapseShow: "hidden",
      toggleSidebar: "",
      radioVal: "0",
      // LOGO,
      openTab: 1,
      specialty: [
        { title: "General Drilling", to: "/General_Drilling" },
        { title: "Drilling Optimization", to: "/Drilling Optimisation" },
        { title: "Well Control", to: "/Well Control" },
        { title: "Directional", to: "/Directional" },
        { title: "Torque & Drag", to: "/Torque & Drag" },
      ],
      dashboard: [
        { title: "Dashboard-1", to: "/Active well1" },
        { title: "Dashboard-2", to: "/General Drilling" },
        { title: "Dashboard-3", to: "/Drilling Optimisation" },
        { title: "Dashboard-4", to: "/Well Control" },
        { title: "Dashboard-5", to: "/Directional" },
        { title: "Dashboard-6", to: "/Torque & Drag" },
      ],
      dashboard_name: "",
      errcode: "",
      dbList: [],
      selected_db: "",
      search_input: "",
      isSearching: false,
      specialityScreens: [],
      customers: [],
      bucketUrl: "",
      clickedLink: "",
      show_sendMail_modal: false,
      capturedImage: null,
      toMail: "support@apollodart.com",
      from: "",
      cc: "",
      login_user_as_cc: "",
      subject: "Issue-",
      body: "",
      attachment: null,
      showCC_Ip: false,
      capture_screen_confirm_box: false,
      mail_Sending: false,
      tomail_disabled: true,
      fileType: null,
      showAttachmentInfo: false,
      isReplay: false,
      getCust: "",
      innerWidth: 10,
      showMask: false,
    };
  },
  setup() {
    const test = ref("Valence");
    return {
      test,
    };
  },
  beforeMount() {
    try {
      this.$store.dispatch("data/setAccessPermissions");
    } catch (error) {
      console.log("error in setAccessPermissions", error);
    }
  },
  async mounted() {
    this.URL = URL;

    try {
      // let themeSelection = this.$router.currentRoute.value["query"].theme
      let themeSelection = localStorage.getItem("theme");
      this.$store.dispatch("rect/toggleDarkMode", themeSelection);
      this.innerWidth = window.innerWidth;
      this.bucketUrl = URL.HOST_URL.BUCKET_URL;
      let apollodart = this.$serviceHelpers.getDetails("apollodart");
      this.getCust = apollodart;
      // let customer = apollodart.customers;
      // this.customers = customer;
      let response = await api.DashBoardServices.getSpecialtyList();
      this.specialty = response.data;
      console.log("specialty data:::::::", this.specialty);
      this.specialty.forEach((sp) => {
        sp["isReplay"] = this.isReplay;
        sp.screen.widgets = sp.screen.widgets.map((w) => {
          return { ...w, isReplay: false };
        });
      });
      if (
        apollodart.user &&
        apollodart.user.entity &&
        apollodart.user.entity.entity_name
      ) {
        this.widgets = this.$store.state.rect.rects;
        this.gettingTheCustomerInfo();

        // //assigning value to from address
        // this.from = apollodart.user?.user_name;
        // this.login_user_as_cc = apollodart.user?.user_name;
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async hide_n_show_header(){
      let show_or_hide = Object.keys(this.$store.state.data).includes('main_nav_show') ? this.$store.state.data.main_nav_show : true;
      await this.$store.dispatch("data/showHideHeader", !show_or_hide);
    },
    scrollArrowClicked(arrow) {
      let scrollDiv = document.querySelector(".widget__overflow");

      scrollDiv.scrollWidth;

      if (arrow === "left") {
        scrollDiv.scrollLeft -= 100;
      } else {
        scrollDiv.scrollLeft += 100;
      }
      console.log(
        "scrolldivwidth:::::",
        scrollDiv.scrollWidth,
        scrollDiv.scrollLeft,
        scrollDiv.clientWidth
      );
      if (
        scrollDiv.scrollLeft + scrollDiv.clientWidth ===
          scrollDiv.scrollWidth ||
        scrollDiv.scrollLeft + scrollDiv.clientWidth > scrollDiv.scrollWidth
      ) {
        document.querySelector(".right_arrow_div").style.visibility = "hidden";
      } else {
        document.querySelector(".right_arrow_div").style.visibility = "visible";
      }
      if (scrollDiv.scrollLeft === 0) {
        document.querySelector(".left_arrow_div").style.visibility = "hidden";
      } else {
        document.querySelector(".left_arrow_div").style.visibility = "visible";
      }
    },
    getTopPostionsForArrows() {
      let navbar_2 = document.querySelector(".navBar2");
      let scrollDiv = document.querySelector(".widget__overflow");

      let obj = {};
      if (navbar_2) {
        obj.top = navbar_2.getBoundingClientRect().top + 3 + "px";
        return obj;
      }
    },
    mouseWheelForWidgetScroll(event) {
      let scrollDiv = document.querySelector(".widget__overflow");
      if (event.deltaY !== 0) {
        scrollDiv.scrollLeft += event.deltaY * 0.5;
      }
      if (
        scrollDiv.scrollLeft + scrollDiv.clientWidth ===
          scrollDiv.scrollWidth ||
        scrollDiv.scrollLeft + scrollDiv.clientWidth > scrollDiv.scrollWidth
      ) {
        document.querySelector(".right_arrow_div").style.visibility = "hidden";
      } else {
        document.querySelector(".right_arrow_div").style.visibility = "visible";
      }
      if (scrollDiv.scrollLeft === 0) {
        document.querySelector(".left_arrow_div").style.visibility = "hidden";
      } else {
        document.querySelector(".left_arrow_div").style.visibility = "visible";
      }
    },
    async gettingTheCustomerInfo() {
      try {
        let entityName;
        console.log(
          "new users Data>>>>>>to Know Customerts",
          this.getCust,
          this.is_super_admin
        );
        if (this.$store.state.data.customer) {
          entityName = this.$store.state.data.customer.split("_")[0];
        }
        if (entityName) {
          let subEntitiesRelatedToEntity =
            await api.SupportServices.gettingUsersRelatedToEntity({
              entityName,
            });
          console.log(
            "new users Data>>>> from adiminnnnn",
            subEntitiesRelatedToEntity.data.subentities
          );
          let subEntityData = subEntitiesRelatedToEntity.data.subentities;
          let subEntityList = [];
          let roleType = [];
          for (let i of subEntityData) {
            subEntityList.push(i.subEntityName);
            for (let j of i.usersRoles) {
              roleType.push(j.roleName);
            }
          }
          this.usersData = subEntitiesRelatedToEntity.data;
          console.log("new users Data>>>>", subEntityList, roleType);
          let dtlsOfuser = {
            subEntities: subEntityList,
            roleType: roleType,
            data: this.usersData,
          };
          this.$store.dispatch("data/settingUsersData", dtlsOfuser);
        }
        // let entityName = localStorage.getItem('entityId').split('_')[0].toLocaleLowerCase()
      } catch (error) {
        console.error(error);
      }
    },
    async replayclickedhandler() {
      console.log("details");
      this.specialty.forEach((sp) => {
        if (
          sp.name === "DRILLING INTERVENTION SYSTEM" ||
          sp.name === "GENERAL DRILLING" ||
          sp.name === "HYDRAULICS" ||
          sp.name == "TORQUE AND DRAG"
        ) {
          sp.isReplay = this.isReplay;
          sp.screen.widgets = sp.screen.widgets.map((w) => {
            return { ...w, isReplay: this.isReplay };
          });
        }
      });
    },

    showHideSettingsPopUp() {
      // console.log("Alert--->>>>>adminNav show hide method");
      for (let i of this.isDisplayIdThere) {
        if (i.name === "alertScreen") {
          console.log(
            "Alert---show hide function>>>>>>in adiminNav inner",
            this.$store.state.disp.showIngSettingsPopUp
          );
          this.$store.state.disp.showIngSettingsPopUp = false;
        }
      }
      this.showIngSettingsPopUp;
      console.log("show hide showing setting popup", this.showIngSettingsPopUp);
    },
    OnNotificationIconClicked(event, popup) {
      // let showing = this.$store.state.disp.showIngSettingsPopUp;
      // this.$store.state.disp.showIngSettingsPopUp = !showing;
      // console.log("Alert---settings clicked", showing);
      // createPopper(this.$refs.settingsBtnPoper, this.$refs.settingsPopUpPoper, {
      //   placement: "bottom-end",
      // });
      if (popup === "alert") {
        let menu_top_setting = document.querySelector("#menu_top_setting");
        if (menu_top_setting) {
          if (menu_top_setting.style.visibility == "visible")
            menu_top_setting.style.visibility = "hidden";
        }

        let top_menu = document.querySelector("#menu_top");
        if (top_menu) {
          if (top_menu.style.visibility == "visible")
            top_menu.style.visibility = "hidden";
          else top_menu.style.visibility = "visible";
        }
      } else if (popup === "theme") {
        let ele = document.getElementById("themes_container_popup");
        if (ele) {
          if (ele.style.visibility === "visible") {
            ele.style.visibility = "hidden";
            ele.style.opacity = 0;
          } else {
            ele.style.visibility = "visible";
            ele.style.opacity = 1;
          }
        }

        let top_menu = document.querySelector("#menu_top");
        if (top_menu) {
          if (top_menu.style.visibility == "visible")
            top_menu.style.visibility = "hidden";
        }
        let menu_top_setting = document.querySelector("#menu_top_setting");
        if (menu_top_setting) {
          if (menu_top_setting.style.visibility == "visible")
            menu_top_setting.style.visibility = "hidden";
        }
      }

      event.stopPropagation();
    },
    OnSettingsIconClicked(event) {
      // let showing = this.$store.state.disp.showIngSettingsPopUp;
      // this.$store.state.disp.showIngSettingsPopUp = !showing;
      // console.log("Alert---settings clicked", showing);
      // createPopper(this.$refs.settingsBtnPoper, this.$refs.settingsPopUpPoper, {
      //   placement: "bottom-end",
      // });
      let top_menu = document.querySelector("#menu_top_setting");
      if (top_menu) {
        if (top_menu.style.visibility == "visible")
          top_menu.style.visibility = "hidden";
        else top_menu.style.visibility = "visible";
      }

      // event.stopPropagation();
    },

    startDragSaved(ev, props) {
      console.log("props in adminNav:::::::", props);
      const rect = ev.target.getBoundingClientRect();
      let offsetX = ev.clientX - rect.x;
      let offsetY = ev.clientY - rect.y;
      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";
      let elProps = {
        ...props,
        offsetY: offsetY,
        offsetX: offsetX,
        isSaved: true,
      };
      let propSring = JSON.stringify(elProps);
      ev.dataTransfer.setData("elProps", propSring);
    },
    gettingDisplayId(scrnName) {
      for (var data of this.$store.state.disp.rects) {
        if (scrnName == data.savedName) {
          return data.displayId;
        }
      }
    },
    onBlurSelect() {
      setTimeout(() => {
        this.isSearching = false;
      }, 200);
    },
    onSelectDb(db) {
      this.selected_db = db;
      this.search_input = db.name;
      this.isSearching = false;
      this.searchList = this.dbList.filter(function (element) {
        return element.name.toLowerCase().indexOf(db.name.toLowerCase()) > -1;
      });
    },
    filterFunction(e) {
      this.isSearching = true;
      this.searchList = this.dbList.filter(function (element) {
        return (
          element.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        );
      });
    },
    async toggleModal() {
      if (
        document
          .getElementById("dashboard-modal")
          .classList.value.indexOf("hidden") > -1
      ) {
        for (let rect of this.$store.state.disp.rects) {
          if (!rect.isMinimize) {
            let displayDtls = this.$store.state.disp.displays[rect.displayId];
            if (!displayDtls) {
              this.$toast.error("PLEASE SELECT WELL DETAILS FOR ALL SCREENS", {
                position: "top",
                duration: "2000",
              });
              return {};
            }
          }
        }
        let response = await api.DashBoardServices.getSavedContentList({
          dbType: "dashboard",
        });
        this.selected_db = "";
        this.dbList = response.data;
        this.searchList = response.data;
        this.search_input = "";
      }
      this.errcode = "";
      this.dashboard_name = "";
      document.getElementById("dashboard-modal").classList.toggle("hidden");
      // alert("model works")
    },

    toggleUserProfile() {
      this.showProfile = !this.showProfile;
    },
    async onSaveDashboard() {
      //
      if (this.radioVal == "0" && !this.dashboard_name) {
        this.errcode = "ENTER DASHBOARD NAME";
        this.$toast.error("ENTER DASHBOARD NAME", {
          position: "top",
          duration: "2000",
        });
        return {};
      }
      if (this.radioVal == "0" && this.dashboard_name.length > 14) {
        this.errcode = "NAME MUST BE LESS THAN 15 CHARACTERS";
        this.$toast.error("NAME MUST BE LESS THAN 15 CHARACTERS", {
          position: "top",
          duration: "2000",
        });
        return {};
      }
      if (this.radioVal == "0") {
        let nameExists = await api.DashBoardServices.getNameExists({
          name: this.dashboard_name,
        });
        if (nameExists && nameExists.data) {
          this.errcode = "NAME ALREADY EXISTS";
          this.$toast.error("NAME ALREADY EXISTS", {
            position: "top",
            duration: "2000",
          });
          return {};
        }
      }
      let dashboardSave = {
        customerId: 1,
        name: this.dashboard_name,
        dbId: null,
        dbType: "dashboard", // 'Dashboard' / 'Screen' / 'Background'
        dashboard: {
          screens: [],
        },
      };
      let listDisplay = {};
      for (let rect of this.$store.state.disp.rects) {
        if (listDisplay[rect.displayId]) {
          // Do Nothing
        } else {
          if (!rect.isMinimize) {
            listDisplay[rect.displayId] = {
              name: rect.name,
              top: rect.top,
              left: rect.left,
              height: rect.height,
              width: rect.width,
            };
          }
        }
      }
      let displaySave = {};
      for (let display in listDisplay) {
        displaySave = {
          ...listDisplay[display],
          background: [],
          widgets: [],
        };
        let displayDtls = this.$store.state.disp.displays[display];
        if (displayDtls) {
          displaySave.wellDetails = {
            logId: displayDtls.logId,
            logType: displayDtls.logType,
            max: displayDtls.man,
            min: displayDtls.min,
            table: displayDtls.table,
            wellboreId: displayDtls.wellboreId,
            wellboreState: displayDtls.wellboreState,
            wellId: displayDtls.wellId,
          };
        }
        let widgets = [];
        let bgs = {};
        for (let rect of this.$store.state.rect.rects) {
          // alert(rect['backgroundId'] + ' ' +rect['bgId'] +' '+ rect['name'])
          if (rect["displayId"] == display) {
            if (rect["name"] == "Background") {
              bgs[rect["backgroundId"]] = {
                name: "Background", // Background
                left: rect.left,
                top: rect.top,
                width: rect.width,
                height: rect.height,
                zIndex: rect.zIndex,
                color: rect.color,
                widgets: [],
              };
            } else if (rect["bgId"]) {
              bgs[rect["bgId"]].widgets.push({
                ...rect,
                name: rect.name, // Numeric / Table
                label: [rect.title], // eg : 'DBTM', [GTF, MTF]
                left: rect.left,
                top: rect.top,
                width: rect.width,
                height: rect.height,
                zIndex: rect.zIndex,
                color: rect.color,
              });
            } else {
              let otherProps = {};
              if (rect.name == "plotly-graph") {
                //
                otherProps.mnemonicTag1 = rect.mnemonicTag1;
                otherProps.mnemonicTag3 = rect.mnemonicTag3;
                otherProps.mnemonicTag4 = rect.mnemonicTag4;
                otherProps.mnemonicTag2 = rect.mnemonicTag2;
              }
              widgets.push({
                ...rect,
                ...otherProps,
                name: rect.name, // Numeric / Table
                label: [rect.title], // eg : 'DBTM', [GTF, MTF]
                left: rect.left,
                top: rect.top,
                width: rect.width,
                height: rect.height,
                zIndex: rect.zIndex,
                color: rect.color,
              });
            }
          }
        }
        for (let bg in bgs) {
          displaySave.background.push(bgs[bg]);
        }
        displaySave.widgets = widgets;
        dashboardSave.dashboard.screens.push(displaySave);
      }
      if (this.radioVal == "1") {
        if (!this.selected_db) {
          this.errcode = "Dashboard is not selected";
          this.$toast.error("Dashboard is not selected", {
            position: "top",
            duration: "2000",
          });
          return {};
        }
        dashboardSave.id = this.selected_db._id;
        dashboardSave.name = this.selected_db.name;
      }
      let response = await api.DashBoardServices.saveDashboard(dashboardSave);
      this.$store.dispatch("disp/updateCall", {});
      this.toggleModal();
      this.$toast.success("Dashboard Saved", {
        position: "top",
        duration: "2000",
      });
    },

    startDrag(ev, props) {
      //
      const rect = ev.target.getBoundingClientRect();
      let offsetX = ev.clientX - rect.x;
      let offsetY = ev.clientY - rect.y;
      // ev.target.classList.add("activenav");
      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";
      let elProps = {
        name: props.name,
        offsetY: offsetY,
        offsetX: offsetX,
        color: props.color,
        height: props.height,
        width: props.width,
        borderDisplay: props.borderDisplay,
        aspectRatio: props.aspectRatio,
        minh: props.minh,
        minw: props.minw,
        displayId: props.displayId ? props.displayId : "",
        ...props,
      };
      let propSring = JSON.stringify(elProps);
      ev.dataTransfer.setData("elProps", propSring);
      ev.dataTransfer.setData("offsetY", offsetY);
      let elProps1 = ev.dataTransfer.getData("offsetY");
      //
      // let container
      // setTimeout(function(props){
      //    container = document.querySelectorAll(".content-container")
      //
      //   if(container.length > 0){
      //   }
      // },1000)
      if (this.openTab === 3 && this.$store.state.disp.rects.length > 0) {
        this.$store.state.rect.whichIsActiveInOpentab3.push(props.name);
      }
    },
    toggleTabs: async function (tabNumber) {
      if (tabNumber === 4) {
        this.isReplay = true;
        await this.replayclickedhandler();
        this.openTab = tabNumber;
      } else {
        this.isReplay = false;
        await this.replayclickedhandler();
        this.openTab = tabNumber;
      }
    },
    toggleCollapseShow: function (classes) {
      // this.collapseShow = classes;
      if (!this.$store.state.uis.leftSideBarOpen) {
        this.toggleSidebar = "0";
      }
    },
    toggleServices: function (tabService) {
      this.openService = tabService;
    },
    toggleLeftSideBar: function () {
      if (!this.$store.state.uis.leftSideBarOpen) {
        this.toggleSidebar = "0";
      } else {
        // this.toggleCollapseShow('bg-white m-2 py-3 px-6')
      }
      this.$store.dispatch("uis/toggleLeftSideBar");
      // this.$store.dispatch("disp/toggleLeftSideBar");
    },
    toggleLeftSideDbBar: async function (action) {
      await this.onToggleSideBar(action);
      (await this.$store.state.uis.leftSideBarOpen)
        ? (this.clickedLink = action)
        : (this.clickedLink = "");
    },
    stopPropagation(event) {
      event.stopPropagation();
    },
    toggleDropdown: function (event) {
      let top_menu = document.querySelector("#menu_top");
      if (top_menu) {
        if (top_menu.style.visibility == "visible")
          top_menu.style.visibility = "hidden";
      }
      let ele = document.getElementById("themes_container_popup");
      if (ele) {
        if (ele.style.visibility === "visible") {
          ele.style.visibility = "hidden";
          ele.style.opacity = 0;
        }
      }
      if (event != undefined) {
        event.preventDefault();
        event.stopPropagation();
        if (this.dropdownPopoverShow) {
          this.dropdownPopoverShow = false;
        } else {
          this.dropdownPopoverShow = true;
          // createPopper(
          //   this.$refs.btnDropdownRef,
          //   this.$refs.popoverDropdownRef,
          //   {
          //     placement: "bottom-start",
          //   }
          // );
        }
      } else {
        this.dropdownPopoverShow = false;
      }

      this.OnSettingsIconClicked({});
    },
    dark() {
      document.querySelector("body").classList.add("dark-mode");
      // this.darkMode = true
      if (this.$store.state.rect.darkmode) {
      }
      this.$emit("dark");
    },
    light() {
      document.querySelector("body").classList.remove("dark-mode");
      // this.darkMode = false
      if (!this.$store.state.rect.darkmode) {
      }
      this.$emit("light");
    },
    modeToggle(theme) {
      console.log(
        "whenever theme toggled:::::",
        getComputedStyle(document.documentElement).getPropertyValue(
          "--transition"
        )
      );
      if (theme == "white") {
        this.isWhiteChecked = true;
        this.isBlueChecked = false;
        this.isGreyChecked = false;
      } else if (theme == "blue") {
        this.isWhiteChecked = false;
        this.isBlueChecked = true;
        this.isGreyChecked = false;
      } else {
        this.isWhiteChecked = false;
        this.isBlueChecked = false;
        this.isGreyChecked = true;
      }
      localStorage.setItem("theme", theme);
      this.$store.dispatch("rect/toggleDarkMode", theme);
      // this.$router.replace({
      //    query: { ...this.$route.query, theme:theme },
      //  });
      // if (
      //   this.$store.state.rect.darkmode ||
      //   document.querySelector("body").classList.contains("dark-mode")
      // ) {
      //   this.light();
      // } else {
      //   this.dark();
      // }
      // darkDark == 'true' && document.querySelectorAll(':root').style.setProperty('--nav1BgClr','white')
    },
    captureScreen_SendMail() {
      this.capture_screen_confirm_box = true;
    },
    async yes_capture_screen() {
      this.show_sendMail_modal = true;
      this.showMask = false;
    },
    captureYesHandler() {
      // this.show_sendMail_modal = true;
      this.showMask = true;
      this.capture_screen_confirm_box = false;
      this.$refs.sendMainComp.yes_capture_screen();

      //
    },
    dont_capture_screen() {
      this.capture_screen_confirm_box = false;
      this.show_sendMail_modal = true;
    },
    close_sendMail_modal() {
      this.show_sendMail_modal = false;
    },
  },
  computed: {
    main_nav_show(){
      return Object.keys(this.$store.state.data).includes('main_nav_show') ? this.$store.state.data.main_nav_show : true;
    },
    is_super_admin() {
      console.log(
        "🚀 ➽ file: AdminNavbar.vue:1913  ➽ is_super_admin  ➽ this.$store.state.data.is_super_admin ⏩",
        this.$store.state.data.is_super_admin
      );
      return this.$store.state.data.is_super_admin;
    },

    all_access() {
      return this.$store.state.data.access_all;
    },
    selectedCustomer() {
      return this.$store.state.data.customer;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    dragedWidgetNames() {
      return this.$store.state.rect.whichIsActiveInOpentab3;
    },
    isDisplayIdThere() {
      return this.$store.state.disp.displayIdsAndNames;
    },
    displayedRects() {
      return this.$store.state.disp.rects.map((each) => each.displayId);
    },
    showIngSettingsPopUp() {
      return this.$store.state.disp.showIngSettingsPopUp;
    },
  },
  watch: {
    selectedCustomer() {
      this.gettingTheCustomerInfo();
    },
  },
};
</script>
<style>
.widget__overflow {
  overflow-x: overlay;
  overflow-y: hidden;
}
.widget__overflow::-webkit-scrollbar {
  height: 3px !important;
}
.bg_gray {
  background-color: #141414;
}

.bg_gray_600 {
  background-color: #2e2e2e;
}

.nav_bar {
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  font-size: 14px;
  /* font-weight: 500; */
  color: #ffffff50;
}

.ml-6rem {
  margin-left: 6.6rem;
}

/* .ml-4rem {
  margin-left: 4.4rem;
} */
.ml-4rem {
  margin-left: 3.7rem;
}

.nav_item {
  padding: 0.8rem 0.2rem;
}

.nav_item.active {
  opacity: 1;
  border-bottom: 2px solid #47a08e;
  color: white;
}

.text_light_gray {
  color: #ffffff80;
}

.bg_gray_400 {
  background-color: #414141;
}

.nav_bar_dashboard {
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  font-size: 12px;
  /* font-weight: 500; */
  color: #595959;
}

.mr_5 {
  margin-right: 2rem;
}

.nav_item_dashboard.active {
  color: white;
}

.text_light_gray {
  color: #959595;
}

.btn-dashboard-nav {
  background-color: #414141;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 11px;
  padding-top: 1px;
  padding-right: 0.75rem;
  padding-bottom: 1px;
  padding-left: 0.75rem;
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  /* font-weight: 400; */
}

.dropdown-border-bottom {
  border-bottom: 1px solid #707070;
}

.dropdown_label {
  color: #ffffff70;
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  /* font-weight: 400; */
  font-size: 14px;
}

/* .tab_animation{
  width: 100px;
  background: red;
  position: relative;
  animation: myfirst 5s 2;
  animation-direction: alternate;
}
@keyframes myfirst {
  0%   {background: red; top: 0px;}
  50%  {background: yellow; top: 50px;}
  100%  {background: blue; top: 100px;}
} */
.expand {
  height: 50px;
  /* animation: slideDown 1ms linear; */
  overflow: hidden;
  transition-timing-function: linear;
}

/* .expand {} */
.expand-leave-active.expand-leave-to {
  transition-timing-function: linear;
  transition: height 0.1s;
  height: 0;
}

.text_xs {
  font-size: 8px;
  font-weight: 100;
}

.text_xs_ {
  font-size: 10px;
}

/* @keyframes slideDown{
    from {
        height: 0;
    }
    to {
        height: 50px;
    }
} */
.bg_dark_gray {
  padding: 1px 0;
  margin-bottom: 0;
}

.sub_ {
  padding: 0px;
}

.responsive {
  width: 75%;
  height: auto;
}

/* img {
  max-width: 35%;
} */
#parent {
  position: inherit;
  /* width: 94%; */
  top: 4px;
  margin: 0 auto;
}

#selector {
  padding-top: 0px;
  max-width: 21%;
  /* margin: 0 15px 0 0; */
}

.cust_logo_list {
  max-width: 20% !important;
  width: 100% !important;
  object-fit: contain;
  background-attachment: fixed;
}

.customer_logo {
  width: 100%;
  object-fit: contain;
  background-attachment: fixed;
  height: 7%;
  /* width: 44px; */
  position: fixed;
  right: 0px;
  top: 0;
  z-index: 9999;
  -webkit-background-size: 32px 32px;
  background-size: 21px 24px;
  border: 0;
  /* -webkit-border-radius: 10%; */
  /* border-radius: 10%; */
  display: block;
  margin: 0 10px;
  position: relative;
  height: 26px;
  z-index: 0;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 90%),
    0 4px 6px -2px rgb(0 0 0 / 92%);
}

.cust_drop_list {
  position: absolute;
  top: 33px;
  z-index: 999;
  right: 20px !important;
  background: var(--nav1BgClrsame);
  text-align: center;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

.cust_drop_list_head {
  background: var(--sidebarButtonbg);
  font-size: 10px;
  padding: 2px 0;
}

.cust_drop_list_apps {
  font-size: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: var(--textColor);
}

.appS_ {
  /* width: 75%; */
  margin: 1px auto;
  text-align: center;
  font-size: 11px;
  display: flex;
  justify-content: flex-start;
  background: var(--root_nav);
  /* border-bottom: 1px solid black; */
  /* color: var(--colorBoxPopUpDeleteText); */
  color: var(--textColor);
  /* font-weight: 600; */
}

.appS_ > img {
  width: 17px;
  height: 17px;
  margin: 0 10px;
  padding: 2px;
}

/* .menu_btns{
  margin: 0 40px;
} */
.screen_:hover {
  background: #5aba81;
}

.screen_:active {
  background: #5aba81;
}

.model_height {
  height: 100%;
}

.bg_model {
  background: #0e1016;
  height: 100%;
}

.bg_cancel {
  background: red;
}

.bg_modal_btns {
  width: 50%;
  font-size: 9px;
  padding: 1px;
}

.bg_create {
  background: #5aba81;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) #479669;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.model_bg {
  background: #242b34e8;
  z-index: 999999;
}

[type="radio"] {
  /* border: 1px solid #5aba81 !important; */
}

[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
}

select {
  width: 258px;
  position: relative;
  /* left: -17px; */
  margin: 0;
  border: 1px solid #dde1e4;
  border-top: none;
  font-size: 9pt;
}

datalist {
  display: none;
}

option {
  padding: 3px;
}

option:hover {
  background-color: #11e8ea;
}

h3,
h4 {
  text-align: center;
}

/* to hide datalist arrow in webkit */
input::-webkit-calendar-picker-indicator {
  display: none;
}

.modal_width {
  width: 22%;
}

.rounded-t {
  padding-bottom: 10px;
  font-size: 10px;
}

.modal_body {
  font-size: 10px;
  padding: 0 15px 18px 15px;
}

._table_modal {
  overflow-y: scroll;
  padding-bottom: 0;
}

.text-base {
  line-height: 0;
  color: #e6f22b;
}

.table-fixed {
  /* font-family: Open Sans,sans-serif; */
}

.t_head {
  background: #dee2e62b;
  color: #5aba81;
}

.tbl_mdl {
  margin-top: 10px;
  margin-bottom: 23px;
  height: 97vh;
}

.label_ {
  font-size: 8px;
}

/* css for search and select */
.dropdown-content_select {
  background-color: hsl(0, 0%, 96%);
  overflow: hidden;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-list_select {
  background-color: #f6f6f6;
  position: absolute;
  width: 100%;
  /* max-height: 200px; */
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content_select_a {
  color: black;
  padding: 5px;
  text-decoration: none;
  display: block;
  font-size: 9px;
}

.nav_hide_icon {
  display: flex;
  justify-content: end;
  color: white;
  margin-top: -9px;
  width: 20px;
  /* margin-right: 10px; */
  float: right;
  position: absolute;
  right: 0;
}

.sec_nav_1 {
  width: 2%;
}

.sec_nav_2 {
  /* width: 98%; */
  width: 100%;
}

.second_tab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

._phone {
  background: #1b1a1f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  align-self: center;
  border-radius: 50%;
  font-size: 20px;
  width: 30px;
  transform: rotate(-41deg);
}

.hamburger_icon {
  display: flex;
  align-items: center;
  /* position: absolute;
  left: 14px; */
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 10px;
  /* top: 15px; */
  -webkit-transform: scale(2);
  transform: scale(2);
  transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
}

.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 30px;
  background-color: #e9e9ea;
  cursor: pointer;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}

.switch-btn::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 0px;
  bottom: 0px;
  border-radius: inherit;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  /* transition: 0.4s  ease all;
  -webkit-transition: 0.4s  ease all; */
  /* transition: 0.4s ease all, left 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15); */
}

.switch-btn:active:before {
  /* width: 40px; */
}

.checkbox:checked + .switch-btn {
  background-color: #414141;
}

.checkbox:checked + .switch-btn::before {
  /* left: calc(100% - 10px);
  transform: translateX(-100%); */
  left: calc(100% - 0px);
  /* -webkit-transform: translateX(-100%);*/
  transform: translateX(-100%);
}

.fa-chevron-circle-up {
  background: black;
  border-radius: 100%;
}

.fa-chevron-circle-down {
  background: black;
  border-radius: 100%;
}

/* accordian styles */
.acc_label:after {
  content: "+";
  position: absolute;
  right: 1em;
  /* color: var(--colorBoxPopUpDeleteText); */
  color: var(--textColor);
  font-size: 15px;
}

input:checked + .acc_label:after {
  content: "-";
  /* line-height: 0.8em; */
  font-size: 20px;
}

.accordion__content {
  max-height: 0em;
  /* transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87); */
}

input[name="panel"]:checked ~ .accordion__content {
  /* Get this as close to what height you expect */
  max-height: 50em;
}

.custo_accordian_ {
  max-height: 65vh;
  overflow-y: auto;
}

.logout_accordian {
  display: flex;
  justify-content: center;
  font-size: 11px;
  background: var(--root_nav);
  margin-top: 2px;
  color: var(--textColor);
  /* font-weight: 600; */
}

/* .cust_drop_list::before {
  content: "\1F781";
  font-size: 14px;
  position: absolute;
  right: 2px;
  top: 0px;
  color: var(--sidebarbg);
} */
/* theme toggle */
.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #414141;
  width: 34px;
  height: 16px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox:checked + .ball {
  background-color: red;
}

.theme_icon {
  font-size: 9.5px !important;
}

.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 4px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(14px);
}

:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 3px 7px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.popper {
  font-size: 8px;
}

/* styles for header txt start*/
.navDiv {
  background: var(--nav1BgClrsame);
  height: 48px;
  padding: 0 10px;
  transition: var(--transition);
}

.__close_box {
  background: var(--navBar2Bg);
  font-weight: 800 !important;
  color: var(--activeTextColor) !important;
}

.apollodart_heading {
  margin: 0px;
  /* font-family: "Poppins"; */
  font-weight: 600;
  font-size: 21px;
  text-transform: uppercase;
  color: var(--nav1HdngClrsame);
}

.apollodart_heading > span {
  /* font-family: "Poppins"; */
}

.navMiddleSection {
  padding-left: 40px;
  height: 100%;
  margin: 0 auto;
}

.navMiddleList {
  gap: 20px;
  height: 100%;
}

.navMiddleList li {
  margin-right: 15px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  height: 100%;
}

.navMiddleList li .textColor {
  font-size: min(1.1vw, 16px);
  /* calc(.6vw + .4vh + .55vmin); */
  font-weight: 100;
  letter-spacing: 0.15em;
  color: var(--searchColor);
  /* var(--colorBoxWhiteNum); */
}

.navMiddleList > li > .textColor:hover {
  color: var(--textColor);
  transition: var(--transition);
}

.navMiddleList li .activeTextColor {
  font-size: min(1.1vw, 16px);
  /* calc(0.6vw + 0.3vh + 0.55vmin); */
  letter-spacing: 0.05em;
  color: var(--activeTextColor);
  border-bottom: 2px solid;
  padding-top: 0.95rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}

.navRightSubDiv {
  gap: 25px;
}

.navBar2 {
  background: var(--navBar2Bgsame);
  height: 32px;
  transition: var(--transition);
}

.navBar2 #header > div {
  height: 32px;
  display: flex;
  align-items: center;
}

.navBar2saveBtn {
  margin-bottom: 3.5px;
}

.activeClass {
  color: var(--activeTextColor) !important;
  border-bottom: 2px solid;
}

.bgClr {
  background: var(--activeTextColor);
}

.tgglAsideBar {
  padding: 1px 10px;
  margin-left: 10px;
  color: var(--colorBoxWhiteNum);
}

/* styles for header txt end*/
/* New styles for sub menu by Prerana Kasar */
.sideBarBackground {
  /* background-color: var(--root_nav); */
  width: 40px;
  height: 32px;
}

.menuItem {
  margin-left: 10px;
  /* font-family: "Open Sans"; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: min(1vw, 16px);
  /* <!-- calc(0.60vw + 0.3vh + 0.45vmin); --> */
  letter-spacing: 0.25em;
  text-transform: uppercase;
  background: transparent;
  border: none;
  color: var(--searchColor);
  /* #dad7d7; */
  /* var(--colorBoxWhiteNum); */
}

.menuItem:hover {
  color: var(--textColor);
  transition: var(--transition);
}

.sec_nav_2 .menuItem {
  /* font-size: calc(0.3vw + 0.3vh + 0.35vmin); */
}

.menuItem > div {
  margin: 0 !important;
  padding: 1px 10px !important;
  border: 0 !important;
}

.activenav {
  color: var(--activeTextColor) !important;
  border-bottom: 2px solid;
  cursor: pointer;
}

.activesvg svg path {
  fill: var(--textColor);
}

.activesvgLight path {
  fill: #073099 !important;
}

.customerLabel {
  margin-top: 0;
}

.notificationPopUp {
  width: 75%;
  z-index: 9999;
  position: absolute;
  top: 100%;
  right: 80%;
  visibility: hidden;
}

.settingsPopUp {
  width: 75%;
  z-index: 9999;
  position: absolute;
  top: 100%;
  right: 80%;
  visibility: hidden;
}

.label_alerts {
  padding: 8px;
  margin-top: 10px;
}

.alertScreen {
  background: var(--sidebarbg);
  /* box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15); */
}

.selectAlertType {
  color: var(--textColor);
  font-size: 13px;
  font-weight: 600;
}

/* recently added popup related to alerts */
.rightSideBarNew {
  background: var(--sidebarbg);
  height: calc(100vh - 85px);
  width: 15%;
  right: 5px;
  top: 85px;
  position: fixed;
  overflow-y: auto;
}

.settingsHeader {
  color: #b4b5ba;
  font-size: 14px;
  font-weight: 600;
}

.selectEleAlerts {
  width: fit-content;
}

.option {
  width: fit-content;
}

.alertTypeLabel {
  color: var(--textColor);
}

.widgetContainer {
  background: var(--colorBoxBg);
  /* width: 120px; */
  height: 29px;
  padding: 15px;
  margin: 6px;
}

.widgetName {
  color: var(--textColor);
  font-size: 13px;
}

.headerLabel {
  background: var(--sidebarListBgColor);
  height: 30px;
  color: var(--textColor);
  font-size: 13px;
  cursor: pointer;
  user-select: none;
}

.selectele {
  background-color: var(--rightBarSelecteleBg);
  height: 30px;
  color: var(--textColor);
  font-size: 10px;
  outline: none;
  border: none;
  line-height: normal;
  padding-left: 5px;
}

.selectLabel {
  color: var(--colorBoxHeadingText);
  font-size: 11px;
}

.inScreenAlertHeading {
  color: #b4b5ba;
}

/* .customRadio {
} */
.downArrowIcon {
  padding-top: 1px;
}

.popperOfAlert {
  width: 112%;
}

/* styles for sendmail code starts */
.captr_N_SendMail {
  color: var(--textColor);
}

.sendMail_modal,
.confirm_box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgb(28 29 31 / 70%);
  display: flex;
  z-index: 999999;
  align-items: center;
}

.Child_Of_sendMail_modal {
  /* width: 90%; */
  /* height: 85%; */
  margin: auto;
  border-radius: 2px;
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarbg);
  color: var(--textColor);
}

.appHeader .Child_Of_sendMail_modal {
  background: var(--sidebarbg);
}

.applyFont {
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
}

.fontWeight-600 {
  font-weight: 600;
}

.emailSubmit_Form input {
  border: none;
  border-radius: 3px;
  left: 0;
  height: 30px;
  /* font-size: 13px; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  padding: 0px 20px 2px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  appearance: auto;
  /* background: var(--searchBarBg); */
  /* color: var(--searchColor); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
  font-weight: 600;
}

.emailSubmit_Form textarea {
  border: none;
  border-radius: 3px;
  /* font-size: 13px; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  /* background: var(--searchBarBg); */
  /* color: var(--searchColor); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
  font-weight: 600;
}

.show_Cc_Btn {
  color: var(--activeTextColor);
  border: 1px solid currentColor;
}

.confirm_Box_Icon {
  color: var(--activeTextColor);
}

.Confirm_Dialogue_no {
  color: var(--activeTextColor);
  border: 1px solid currentColor;
}

.confirm_Dia_Box {
  /* background: var(--colorBoxPopUpBg); */
  color: var(--textColor);
  background: var(--sidebarbg);
}

.c-toast-container {
  z-index: 99999999 !important;
}

.sendMailbtn[disabled] {
  opacity: 0.6;
  pointer-events: none;
}

.bg-none {
  filter: opacity(0.6);
}

.addBg {
  /* background: var(--searchBarBg);
  color: var(--searchColor); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
}

.attachmentInfoDiv::before {
  content: "";
  border-bottom: 10px solid var(--searchBarBg);
  border-left: 10px solid transparent;
  position: absolute;
  border-right: 10px solid transparent;
  top: -9px;
  left: 1px;
}

.attachmentInfoDiv {
  top: 35px;
  border-radius: 3px;
  background: var(--searchBarBg);
  margin-left: 1px;
}

.colorBarsOfTheme {
  width: 200px;
  height: 20px;
}

.themModalContainer {
  width: 40px;
  height: fit-content;
  background: var(--root_nav);
  right: 10px;
  top: 20px;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
  padding: 1px;
  border-radius: 5px;
}

.themeHeading {
  font-size: 15px;
  color: var(--searchColor);
}

.circles div {
  width: 100%;
  height: 22px;
  border-radius: 13px;
  /* border-radius: 50%; */
  cursor: pointer;
  border: 1px solid var(--searchColor);
  margin: 5px 0px;
}

.circles img {
  width: 25px;
  height: 25px;
}

.circle1 {
  background-color: #2a2a2a;
}

.circle2 {
  background-color: #fff;
}

.circle3 {
  background-color: #0f172a;
}

.bellIcon_light path {
  fill: #000;
}

.themeActive {
  border: 1px solid var(--activeTextColor) !important;
}

.active_customer {
  background: var(--activeCustomerBg);
}
.right_arrow_div,
.left_arrow_div {
  background-color: white;
  width: 26px;
  height: 26px !important;
  position: fixed;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  right: 30px;
  visibility: hidden;
}
.left_arrow_div {
  /* transform: rotate(180deg); */
  left: 30px;
}

.widget__overflow:hover .right_arrow_div,
.widget__overflow:hover .left_arrow_div {
  visibility: visible;
}
.widget__overflow:not(:hover) .right_arrow_div,
.widget__overflow:not(:hover) .left_arrow_div{
  visibility: hidden !important;
}
.right_arrow_div svg {
  transform: rotate(90deg);
}
.left_arrow_div svg {
  transform: rotate(-90deg);
}

</style>
